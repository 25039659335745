import {
  createTRPCClient,
  createTRPCReact,
  httpBatchLink,
} from "@trpc/react-query";
import SuperJSON from "superjson";
import type { AppRouter } from "~/server/router";
import { APP_PROTOCOL, RAILWAY_PUBLIC_DOMAIN } from "./env";

export const trpc = createTRPCReact<AppRouter>();
export const vanilla = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${APP_PROTOCOL}${RAILWAY_PUBLIC_DOMAIN}/api/trpc`,
      transformer: SuperJSON,
    }),
  ],
});
